import React from 'react'
import PropTypes from 'prop-types'

import { Layout, SearchBar, JoinCommunity, HomeData, DownloadLinks, PhoneNumberInput, Carousel } from '../components/common'
import { MetaData } from '../components/common/meta'

import HomePhones from '../components/images/homePhones'
import PhoneFeed from '../components/images/phoneFeed'
import PhoneLibrary from '../components/images/phoneLibrary'
import PhoneProfile from '../components/images/phoneProfile'

const Index = ({ location, pageContext }) => {
    return (
        <>
            <MetaData location={location} title={pageContext.title} description={pageContext.description}/>
            <Layout isHome={true} location={location} pageContext={pageContext}>
                <div className="container-fluid" style={{padding: "20px", background: "#006AC7", color: "white"}}>
                  <div className="row">
                    <div className="col-12 col-md-5 col-lg-4">
                      <div className="d-flex flex-column align-items-center justify-content-ceter">
                        <h1 style={{fontSize: "calc(1.5rem + 2.0vw)", fontFamily: "SofiaPro", lineHeight: "1.25"}}>The Community for Chronic Conditions</h1>
                        <PhoneNumberInput/>
                      </div>
                    </div>
                    <div className="col-12 col-md-7 col-lg-8">
                      <HomePhones/>
                    </div>
                  </div>
                </div>
                <HomeData homeData={pageContext["homeData"]}/>
                <DownloadLinks/>
                <div className="container-fluid">
                    <div className="row" style={{marginTop: "40px", marginBottom: "40px"}}>
                        <div className="col">
                            <h2 style={{textAlign: "center"}}>Wana in the press</h2>
                            <Carousel/>
                        </div>
                    </div>
                </div>
                <div className="container">
                  <div style={{margin: "40px 0px", padding: "100px", background: "#A6E5E1", borderRadius: "10px"}}>
                    <h2 style={{textAlign: "center", fontSize: "28px"}}>
                      Wana is a community of people that support and learn from each other through shared health experiences.
                    </h2>
                  </div>
                </div>
                <div className="container" style={{padding: "20px"}}>
                  <div className="row">
                    <div className="col-12 col-sm-6 d-none d-sm-block" style={{padding: "20px"}}>
                      <PhoneFeed/>
                    </div>
                    <div className="col-12 col-sm-6" style={{padding: "20px"}}>
                      <img src={"/images/icons/home/community_yellow.svg"} alt="community" style={{height: "40px", width: "40px", marginBottom: "10px"}}/>
                      <h2>Discover a supportive community</h2>
                      <p>Reaching out to the community provides you with a sense of belonging, feeling understood and support. Whether at the beginning, middle or end of your health journey, Wana users offer help, advice, and hope.</p>
                    </div>
                    <div className="col-12 col-sm-6 d-block d-sm-none" style={{padding: "20px"}}>
                      <PhoneFeed/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6" style={{padding: "20px"}}>
                      <img src={"/images/icons/home/library_purple.svg"} alt="community" style={{height: "40px", width: "40px", marginBottom: "10px"}}/>
                      <h2>Learn about your health in the WanaLibrary</h2>
                      <p>The WanaLibrary, written by a team of medical experts, offers curated information on symptoms, treatments, diagnoses and diets. It also provides current books, podcasts and videos to further support your health journey.</p>
                    </div>
                    <div className="col-12 col-sm-6" style={{padding: "20px"}}>
                      <PhoneLibrary/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 d-none d-sm-block" style={{padding: "20px"}}>
                      <PhoneProfile/>
                    </div>
                    <div className="col-12 col-sm-6" style={{padding: "20px"}}>
                      <img src={"/images/icons/home/chat_green.svg"} alt="community" style={{height: "40px", width: "40px", marginBottom: "10px"}}/>
                      <h2>Meet others who share similar symptoms and diagnoses</h2>
                      <p>Wana provides you with curated matches based on your symptoms and diagnoses. Check out each other’s profiles, find commonalities, and communicate directly with them through messaging. Our users love to learn from one another!</p>
                    </div>
                    <div className="col-12 col-sm-6 d-block d-sm-none" style={{padding: "20px"}}>
                      <PhoneProfile/>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="d-flex justify-content-end align-items-center" style={{marginBottom: "-1px", marginRight: "10px"}}>
                    <img src="/images/icons/home/reader.svg" alt="Reader"/>
                  </div>
                  <div style={{margin: "0px", padding: "30px 80px", background: "#006AC7", borderRadius: "10px", color: "white"}}>
                    <h2 style={{textAlign: "center", fontSize: "28px", color: "white"}}>
                      Search the WanaLibrary
                    </h2>
                    <p>Our experts have curated information on hundreds of topics for you to browse and learn from. You will also find user conversations as well as relevant books, podcasts and videos on each topic to further support your health journey.</p>
                    <SearchBar librarySearch={pageContext.librarySearch}/>
                  </div>
                </div>
                <JoinCommunity version={1}/>
            </Layout>
        </>
    )
}

Index.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default Index
